import 'firebase/auth';
import 'firebase/functions';
import React from 'react';

export function wrapPageElement({ element, props }) {
  const Layout = element.type.Layout ?? React.Fragment;
  const isNavbarBackgroundEmpty = element.type.isNavbarBackgroundEmpty ?? false;
  return (
    <Layout {...props} isNavbarBackgroundEmpty={isNavbarBackgroundEmpty}>
      {element}
    </Layout>
  );
}

export function onRouteUpdate({ location, prevLocation }) {
  if (location.pathname !== prevLocation?.pathname) window.previousLocation = prevLocation;
}
